footer {

	padding: 50px 0 50px 0;
	//font-size: 1.1.1rem;
	position: relative;
	border: 1px solid #eee;

	.privacy-policy{
		font-size: .9rem;
	}
	.copyright {
		font-size: .9rem;
		margin: 20px auto 0;
	}

	h2 {
		font-size: 1.4rem;
		margin: 30px 0;
	}

	.footer-columns {
		@media #{$mid-point} {
			display: flex;
		}
		@include flex-flow(wrap);
		list-style: none;
		padding: 0;

		@media #{$mid-point} {
			margin: -10px -60px 10px -60px;
		}

		& > li {
			@media #{$mid-point} {
				flex: 1;
				padding: 0 60px;
			}

			box-sizing: border-box;

			&.footer-nav {
				@media #{$mid-point} {
					flex: 0;
				}
			}
			ul {
				padding: 0;
				list-style: none;
				li {
					//font-size: 1.1rem;
					margin: 11px 0;
					a {
						white-space: nowrap;
						color: #999;
					}
				}
			}
		}

		.about {
			svg path {
				fill: $brand-color;
			}
		}

		p {
			color: #999;
		}
	}

	a {
		text-decoration: none;
	}
}
