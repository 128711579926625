$brand-color: #D7A2A4;

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400,600,700|Montserrat:300,400,500,600,700&display=swap');

$primary-font: 'Josefin Sans', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// Breakpoints
$tablet: "(min-width: 450px)";
$above-tablet: "(min-width: 550px)";
$mid-point: "(min-width: 850px)";
$below-mid-point: "(max-width: 849px)";
$desktop: "(min-width: 768px)";
