.styles {
	width: 100%;
	// background: #eee;
	background: #fff;
	position: relative;
	padding: 20px;
	padding: 0px;
	box-sizing: border-box;
	border-radius: 3px;

	.style-picker {
		z-index: 2;
		display: block;
		line-height: 0;
		position: absolute;
		bottom: 20px;
		right: 20px;

		> div {
			width: 20px;
			height: 20px;
			border: 1px solid #000;
			display: inline-block;
			margin: 0 5px 0 0;
			border-radius: 20px;
			cursor: pointer;
		}
	}

	> div + div {
		display: none;
	}

	i {
		font-size: 14rem;
		color: #fff;
	}
}

.product-container {
	display: flex;
	flex-wrap: wrap;
	margin-left: -40px;

	.styles {
		width: auto;
		flex: 1 1 300px;
		margin-left: 40px;
	}

	.product-details {
		flex: 2 1 500px;
		text-align: left;
		margin-left: 40px;

		h4 {
			color: $brand-color;
		}
	}
}

.product-list {
	display: flex;
	flex-flow: wrap;
	margin: 0 -20px;

	&, > li {
		list-style: none;
		padding: 0;
	}

	> li {
		flex: 1 1 300px;

		&:not(:empty) {
			margin: 20px;
		}
	}

	h4 {
		margin: 10px 0 0 0;
		//padding-right: 100px;
	}

	p {
		line-height: 1;
		margin: 10px 0 0 0;
	}

	.product-description p {
		font-size: 1.1rem;
	}
}